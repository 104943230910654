<template>
  <div class="app-page">
    <!-- loading -->
    <LoadingPop />

    <!-- menu_bar -->
    <MenuBar :swiper="mySwiper" @topage="slideTo" />

    <div
      class="content"
      :class="{ 'home-page': currentPage == 0, 'news-page': currentPage == 2 }"
    >
      <swiper
        :direction="'vertical'"
        class="app-swiper"
        noSwipingClass="stop-swiping"
        :spaceBetween="50"
        @swiper="setSwiper"
        @slideChange="slideChange"
      >
        <!-- 首页page -->
        <swiper-slide class="slide home-bg"><HomePage /></swiper-slide>

        <!-- 概览展示page -->
        <swiper-slide class="slide"><OverviewPage /></swiper-slide>

        <!-- 新闻page -->
        <swiper-slide class="slide">
          <NewsPage v-if="isShow" @showDetail="showDetail" @topage="slideTo" />
        </swiper-slide>

        <!-- 兑换码page -->
        <swiper-slide class="slide"><ExchangeCodePage /></swiper-slide>

        <!-- 联系我们page -->
        <swiper-slide class="slide"
          ><ContactUsPage @topage="slideTo"
        /></swiper-slide>
      </swiper>
    </div>

    <!-- 新闻详情 -->
    <NewsDetail
      v-model:visible="visible"
      @topage="slideTo"
      :id="newsid"
      :active="type"
    />
  </div>
</template>

<script setup>
import isMobile from "@/utils/isMobile";
isMobile();

import { ref, onMounted } from "vue";
import LoadingPop from "./LoadingPop.vue";
import NewsDetail from "./NewsDetail.vue";
import MenuBar from "./MenuBar.vue";

import HomePage from "./HomePage.vue";
import OverviewPage from "./OverviewPage.vue";
import NewsPage from "./NewsPage.vue";
import ExchangeCodePage from "./ExchangeCodePage.vue";
import ContactUsPage from "./ContactUsPage.vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { useRouter, useRoute } from "vue-router";
const route = useRoute();
const router = useRouter();

const visible = ref(false);
const newsid = ref(0);

const type = ref(0);

const currentPage = ref(0);
const mySwiper = ref(null);
const setSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const slideChange = () => {
  // mySwiper.value.allowTouchMove = mySwiper.value.activeIndex != 2;
  currentPage.value = mySwiper.value.activeIndex;
};

const slideTo = (index) => {
  mySwiper.value.slideTo(index - 1, 300);
};

const showDetail = (id, active) => {
  visible.value = true;
  newsid.value = id;
  type.value = active;
};
const isShow = ref(false);
onMounted(() => {
  isShow.value = true;
  if (route.query.newsId) {
    newsid.value = Number(route.query.newsId);
    visible.value = true;
    router.replace("/h5");
  }
});
</script>

<style lang="scss" scoped>
.app-page {
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(to right, #b3d6fa, #f5d3f9);
}

.app-swiper {
  width: 100%;
  height: 100%;
}

.home-bg {
  background: url("@/assets/h5/cbg.png") no-repeat left top;
  background-size: auto 100%;
}

.content {
  position: absolute;
  z-index: 1;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s;
  &.home-page {
    top: 0;
    bottom: 0;
  }
  &.news-page {
    top: 70px;
    bottom: 50px;
  }
}
</style>
